var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    { staticClass: "tooltip-container", class: _vm.position },
    [
      _c("feather-icon", {
        staticClass: "tooltip-icon",
        attrs: { width: 20, height: 20, type: "help-circle" },
      }),
      _c("div", { staticClass: "tooltip-text", style: _vm.tooltipWidth }, [
        _vm.title
          ? _c("h4", { staticClass: "tooltip-title" }, [
              _vm._v("\n      " + _vm._s(_vm.title) + "\n    "),
            ])
          : _vm._e(),
        _c("div", {
          staticClass: "text-left tooltip-content",
          domProps: { innerHTML: _vm._s(_vm.sanitizedContent) },
        }),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }