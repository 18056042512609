<template>
  <span
    class="tooltip-container"
    :class="position"
  >
    <feather-icon
      :width="20"
      :height="20"
      class="tooltip-icon"
      type="help-circle"
    />
    <div class="tooltip-text" :style="tooltipWidth">
      <h4 v-if="title" class="tooltip-title">
        {{ title }}
      </h4>
      <div class="text-left tooltip-content" v-html="sanitizedContent" />
    </div>
  </span>
</template>
<script>
import * as DOMPurify from 'dompurify'

export default {
  name: 'ToolTips',
  components: {
    FeatherIcon: () => import('@/components/shared/FeatherIcon'),
  },
  props: {
    position: {
      type: String,
      default: 'right',
    },
    title: {
      type: String,
      default: null,
    },
    width: {
      type: String,
      default: '25',
    },
    content: String,
  },
  computed: {
    tooltipWidth() {
      return 'width: ' + this.width + 'vw'
    },
    sanitizedContent() {
      return DOMPurify.sanitize(this.content, {
        ALLOWED_TAGS: ['a', 'strong', 'em', 'p', 'br', 'div', 'span', 'li'],
        ALLOWED_ATTR: ['href', 'target', 'rel'],
      })
    },
  },
}
</script>
<style>
span.tooltip-container {
  width: 100%;
  position: relative;
}

div.tooltip-text {
  visibility: hidden;
  background: white;
  color: black;
  text-align: left;
  box-shadow: -2px -2px 2px 1px #00000033,
  -2px 2px 2px 1px #00000033,
  2px -2px 2px 1px #00000033,
  2px 2px 2px 1px #00000033;
  border-radius: 4px;
  padding: 0.75em;
  position: absolute;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-content {
  font-size: initial;
}

/* Positioning */
.top .tooltip-text {
  bottom: 176%;
  left: -88%;
}

.right .tooltip-text {
  top: -63%;
  left: 187%;
}

.bottom .tooltip-text {
  top: 176%;
  left: -88%;
}

.left .tooltip-text {
  top: -63%;
  right: 187%;
}

.tooltip-container .tooltip-text::after {
  content: "";
  position: absolute;
}

/* Arrow */
.top .tooltip-text::after {
  top: 100%;
  left: 3%;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 15px solid #00000033;
}

.right .tooltip-text::after {
  top: 6%;
  right: 100%;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-right: 15px solid #00000033;
}

.bottom .tooltip-text::after {
  bottom: 100%;
  left: 3%;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid #00000033;
}

.left .tooltip-text::after {
  top: 6%;
  left: 100%;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-left: 15px solid #00000033;
}

.tooltip-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

/* Other */
h4.tooltip-title {
  margin: 0;
  padding-bottom: 0.4em;
  color: #333333;
  text-transform: capitalize;
}

ul, li, p {
  margin: 0;
  padding: 0;
}

@media only screen and (max-width: 660px) {
  div.tooltip-text {
    width: 300px;
  }

  /* Positioning */
  .top .tooltip-text,
  .right .tooltip-text,
  .bottom .tooltip-text,
  .left .tooltip-text {
    top: 50%;
    left: -300%;
    transform: translate(-50%, -50%);
  }

  .top .tooltip-text::after,
  .right .tooltip-text::after,
  .bottom .tooltip-text::after,
  .left .tooltip-text::after {
    top: unset;
    right: unset;
    bottom: unset;
    left: unset;
    border: 0;
  }
}
</style>
